.wrapper {
  background: linear-gradient(-45deg, #000000, #A30B37, #BD6B73, #BBB6DF);
  background-size: 400% 400%;
  animation: gradient 22s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  35% {
    background-position: 100% 50%;
  }
  65% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
