.wrapper {
  background: linear-gradient(-45deg, #000000, #a30b37, #bd6b73, #bbb6df);
  background-size: 400% 400%;
  animation: gradient 22s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  35% {
    background-position: 100% 50%;
  }
  65% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
